import { Circle } from '@mui/icons-material';
import { lightGreen, grey } from '@mui/material/colors';
// import { ReactComponent as RequestIcon } from 'assets/icons/plane.svg';
// import { ReactComponent as CancelIcon } from 'assets/icons/task_status/cancel.svg';
// import { ReactComponent as ApprovedIcon } from 'assets/icons/task_status/approved.svg';
// import { ReactComponent as RejectedIcon } from 'assets/icons/task_status/rejected.svg';
import Path from 'config/clientPath';
import Roles from 'constants/roles';

export const REPORT_TYPE = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};

export const ROUTES_INFO = {
  [Path.PROFILE]: {
    useRegex: true,
    role: '',
  },

  // default when use has one role then role must is null and roles is array
};

export const REGEX_VALIDATE_EMAIL =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ASSIGN_GET_MONTH_COUNT = 6;

export const REGEX_CHECK_HEX_CODE = /^[#]*([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i;

export const USER_ROLES = [Roles.IS_ADMIN, Roles.IS_LEADER, Roles.IS_CUSTOMER];

export const LANGUAGE_DEFAULT = {
  en: 'English',
  ja: 'Japan',
};

export const TYPE_MEMBER = {
  O: 'Official',
  R: 'Remote',
  I: 'Intern',
  P: 'Parttime',
};
