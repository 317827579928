import React from 'react';

// UI
import {
  Grid,
  Stack,
  Typography,
  FormLabel,
  TextField,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  Button,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { selectForgotPassword } from 'app/pages/ForgotPasswordPage/slice/selectors';
import DatePicker from '@mui/lab/DatePicker';
import InputImage from 'app/components/InputImage';
import { LANGUAGE_DEFAULT } from 'constants/common';
import SaveIcon from '@mui/icons-material/Save';
// Redux & Sagas
import { RequestStatus } from 'constants/API';
import { useDispatch, useSelector } from 'react-redux';
import {
  Controller,
  UseFormReturn,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';
import { IMemberSchema } from 'app/pages/MemberPage/slice/types';
import { useMemberSlice } from 'app/pages/MemberPage/slice';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import _ from 'lodash';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { TYPE_MEMBER } from 'constants/common';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';
interface IBasicInfoProps {
  memberForm: UseFormReturn;
  changePasswordForm: UseFormReturn;
  handleSaveData: SubmitHandler<FieldValues>;
  handleChangePassword: SubmitHandler<FieldValues>;
  callAPIUploadImage: any;
  showPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  handleClickShowPassword: any;
  handleClickShowNewPassword: any;
  handleClickShowConfirmPassword: any;
  handleMouseDownPassword: any;
}
interface IResetPasswordForm {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

export default function BasicInfo(props: IBasicInfoProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    actions: { getProjectRoleListRequest },
  } = useMemberSlice();
  const { setUserSessionData } = useGlobalSlice().actions;
  const { changePasswordStatus } = useSelector(selectForgotPassword);
  const { updateMemberInfoStatus, memberInfo, uploadMemberAvatarStatus } =
    useSelector(selectMember);
  const { userSessionData } = useSelector(selectGlobal);

  const {
    register,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = props.memberForm;
  const changePasswordForm = props.changePasswordForm;
  React.useEffect(() => {
    dispatch(getProjectRoleListRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateNewMemberInfo = () => {
    const newMemberInfo = {
      ...userSessionData,
      profile: {
        ...userSessionData.profile,
        avatar: memberInfo?.avatar || '',
        email: memberInfo?.email || '',
        full_name: memberInfo?.full_name || '',
        gender: _.toNumber(memberInfo?.gender) || 0,
        sys_language: memberInfo?.sys_language || '',
      },
    };
    dispatch(setUserSessionData(newMemberInfo));
  };

  React.useEffect(() => {
    if (updateMemberInfoStatus === RequestStatus.SUCCESS) {
      const data = _.cloneDeep(memberInfo);
      props.memberForm.reset(data);
      updateNewMemberInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMemberInfoStatus]);

  React.useEffect(() => {
    if (uploadMemberAvatarStatus === RequestStatus.SUCCESS) {
      updateNewMemberInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadMemberAvatarStatus]);
  const handleResetData = () => {
    props.memberForm.reset();
    props.changePasswordForm.reset();
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} lg={8}>
        <Stack>
          <Typography variant="h5" component="h5">
            {t('Personal Information')}
          </Typography>

          <Grid container spacing={2} pb={4} pt={2}>
            <Grid item xs={5}>
              <FormLabel>{t('Full Name')}</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                error={errors.full_name ? true : false}
                {...register<keyof IMemberSchema>('full_name', {
                  required: 'This is required.',
                  maxLength: 60,
                })}
                disabled
                helperText={errors.full_name?.message}
              />
            </Grid>

            <Grid item xs={5}>
              <FormLabel>{t('Employee Code')}</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                multiline
                rows={1}
                disabled
                error={errors.member_code ? true : false}
                {...register<keyof IMemberSchema>('code', {
                  maxLength: 5,
                })}
                helperText={errors.member_code?.message}
              />
            </Grid>

            <Grid item xs={10}>
              <FormLabel>{t('Email')}</FormLabel>
              <TextField
                value={getValues('email')}
                fullWidth
                size="small"
                margin="dense"
                disabled
              />
            </Grid>

            <Grid item xs={5}>
              <FormLabel>{t('Phone')}</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                error={!!errors.phone}
                {...register<keyof IMemberSchema>('phone', {
                  required: 'This is required.',
                  maxLength: 12,
                })}
                helperText={errors.phone?.message}
              />
            </Grid>
            <Grid item xs={5}>
              <FormLabel>{t('DOB')}</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="birthday"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      disableFuture
                      disabled
                      value={value}
                      onChange={onChange}
                      inputFormat="DD-MM-YYYY"
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          hiddenLabel
                          size="small"
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={10}>
              <FormLabel>{t('Address')}</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                error={!!errors.address}
                {...register<keyof IMemberSchema>('address', {
                  required: 'This is required.',
                  maxLength: 255,
                })}
                helperText={errors.address?.message}
              />
            </Grid>

            <Grid item xs={7}>
              <FormLabel>{t('Education')}</FormLabel>
              <TextField
                value={getValues('education')}
                fullWidth
                size="small"
                margin="dense"
                disabled
              />
            </Grid>

            <Grid item xs={3}>
              <FormLabel>{t('Language')}</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="sys_language"
                  // rules={{ required: 'This is required.' }}
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        displayEmpty
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value ? `${value}` : 'en'}
                        defaultValue=""
                      >
                        {_.map(LANGUAGE_DEFAULT, (label, code) => (
                          <MenuItem key={code} value={code}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
                <FormHelperText error>{errors.type?.message}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid xs={5}>
            <Stack direction="row" spacing={2}>
              <LoadingButton
                variant="contained"
                color="primary"
                loadingPosition="start"
                startIcon={<SaveIcon />}
                loading={updateMemberInfoStatus === RequestStatus.REQUESTING}
                onClick={() => handleSubmit(props.handleSaveData)()}
              >
                {t('Save change')}
              </LoadingButton>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#F5F5F5',
                  color: 'rgb(0 0 0 / 30%)',
                }}
                onClick={handleResetData}
              >
                {t('Reset')}
              </Button>
            </Stack>
          </Grid>
          <Typography variant="h5" pt={5} component="h5">
            {t('Change password')}
          </Typography>

          <Grid container spacing={2} pb={4} pt={2}>
            <Grid item xs={5}>
              <Grid item xs={12}>
                <FormLabel>{t('Current Password')}</FormLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  size="small"
                  {...changePasswordForm.register<keyof IResetPasswordForm>(
                    'old_password',
                    {
                      required: 'This is required.',
                    },
                  )}
                  placeholder={t('Enter password')}
                  error={!!changePasswordForm.formState.errors.old_password}
                  helperText={
                    changePasswordForm.formState.errors.old_password?.message
                  }
                  type={props.showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={props.handleClickShowPassword}
                          onMouseDown={props.handleMouseDownPassword}
                          edge="end"
                        >
                          {props.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormLabel>{t('New Password')}</FormLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  size="small"
                  {...changePasswordForm.register<keyof IResetPasswordForm>(
                    'new_password',
                    {
                      required: 'This is required.',
                    },
                  )}
                  placeholder={t('Enter password')}
                  error={!!changePasswordForm.formState.errors.new_password}
                  helperText={
                    changePasswordForm.formState.errors.new_password?.message
                  }
                  type={props.showNewPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={props.handleClickShowNewPassword}
                          onMouseDown={props.handleMouseDownPassword}
                          edge="end"
                        >
                          {props.showNewPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t('Confirm Password')}</FormLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  size="small"
                  {...changePasswordForm.register<keyof IResetPasswordForm>(
                    'confirm_password',
                    {
                      required: 'This is required.',
                      validate: value =>
                        value === changePasswordForm.watch('new_password') ||
                        'Passwords do not match.',
                    },
                  )}
                  placeholder={t('Enter password')}
                  error={!!changePasswordForm.formState.errors.confirm_password}
                  helperText={
                    changePasswordForm.formState.errors.confirm_password
                      ?.message
                  }
                  type={props.showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={props.handleClickShowConfirmPassword}
                          onMouseDown={props.handleMouseDownPassword}
                          edge="end"
                        >
                          {props.showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={4}>
            <Stack direction="row" spacing={2}>
              <LoadingButton
                variant="contained"
                color="primary"
                loadingPosition="start"
                startIcon={<SaveIcon />}
                loading={changePasswordStatus === RequestStatus.REQUESTING}
                onClick={() =>
                  changePasswordForm.handleSubmit(props.handleChangePassword)()
                }
              >
                {t('Update')}
              </LoadingButton>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#F5F5F5',
                  color: 'rgb(0 0 0 / 30%)',
                }}
                onClick={handleResetData}
              >
                {t('Reset')}
              </Button>
            </Stack>
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12} lg={4} display="flex" alignItems="center">
        <InputImage
          form={props.memberForm}
          inputName="avatar"
          size={300}
          callAPIUploadImage={props.callAPIUploadImage}
        />
      </Grid>
    </Grid>
  );
}
