import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { GlobalState, IUserSessionData, IBreadcumbs } from './types';
import {
  getUserCachedDataFromLocalStorage,
  setUserCachedDataFromLocalStorage,
  removeToken,
  removeUserCachedDataFromLocalStorage,
  setIsShowSidebar,
  isShowSidebar,
} from 'utils/localStorage';

const defaultUserInfo = {
  auth: {
    access: '',
    refresh: '',
  },
  profile: {
    id: 0,
    email: '',
    full_name: '',
    avatar: '',
    gender: 1,
    role: '',
    skill: [],
    type: '',
    sys_language: '',
  },
  roles: [],
  userRole: '',
};

export const retrieveUserCachedDataFromStorage = () => {
  let userInfo = getUserCachedDataFromLocalStorage();
  if (!userInfo) {
    userInfo = { ...defaultUserInfo };
  }
  return userInfo;
};

export const initialState: GlobalState = {
  isGlobalLoading: false,
  isShowSidebar: isShowSidebar(),
  /* Messages */
  errorMessages: [],
  successMessages: [],
  infoMessages: [],
  warningMessages: [],
  isPasswordRemembered: false,
  userSessionData: retrieveUserCachedDataFromStorage(),
  breadcumbs: [],
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsGlobalLoading: (state, action: PayloadAction<boolean | undefined>) => {
      if (typeof action.payload === 'boolean') {
        state.isGlobalLoading = action.payload;
      } else {
        state.isGlobalLoading = !state.isGlobalLoading;
      }
    },
    setSuccessMessages: (
      state,
      action: PayloadAction<Array<string | undefined>>,
    ) => {
      state.successMessages = action.payload;
    },
    setInfoMessages: (
      state,
      action: PayloadAction<Array<string | undefined>>,
    ) => {
      state.infoMessages = action.payload;
    },
    setWarningMessages: (
      state,
      action: PayloadAction<Array<string | undefined>>,
    ) => {
      state.warningMessages = action.payload;
    },
    setErrorMessages: (
      state,
      action: PayloadAction<Array<string | undefined>>,
    ) => {
      state.errorMessages = action.payload;
    },
    setIsPasswordRemembered: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      if (action.payload === undefined) {
        state.isPasswordRemembered = !state.isPasswordRemembered;
      } else {
        state.isPasswordRemembered = action.payload;
      }
    },
    setUserSessionData: (state, action: PayloadAction<IUserSessionData>) => {
      state.userSessionData = action.payload;
      setUserCachedDataFromLocalStorage(action.payload);
    },

    logout: state => {
      removeToken();
      removeUserCachedDataFromLocalStorage();
      state.userSessionData = defaultUserInfo;
    },
    setBreadcumbs: (state, action: PayloadAction<IBreadcumbs[]>) => {
      state.breadcumbs = action.payload;
    },
    setIsShowSidebar: (state, action: PayloadAction<boolean>) => {
      state.isShowSidebar = action.payload;
      setIsShowSidebar(action.payload);
    },
  },
});

export default slice.actions;

export const useGlobalSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
