/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import Path from 'config/clientPath';
import { Helmet } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { GlobalStyle } from 'styles/global-styles';
import { HomePage } from './pages/HomePage';
import { LoginPage } from './pages/LoginPage';
import ProtectedRoute from './components/ProtectedRoute';
import { GlobalContainer } from './pages/GlobalContainer';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { NotFoundPage } from './components/NotFoundPage';

import { useRole } from 'utils/role-hook';
import Roles from 'constants/roles';
import { isHavingToken } from 'utils/localStorage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { selectLogin } from './pages/LoginPage/slice/selectors';
import { useSelector } from 'react-redux';
import { RequestStatus } from 'constants/API';
import { ROUTES_INFO } from 'constants/common';
import { ProfilePage } from './pages/ProfilePage';

const ROUTE_LIST = [
  {
    component: <Route exact path={Path.HOME} component={HomePage} />,
    role: '',
    useRegex: true,
  },

  {
    component: (
      <ProtectedRoute exact path={Path.PROFILE} component={ProfilePage} />
    ),
    role: ROUTES_INFO[Path.PROFILE].role,
    useRegex: ROUTES_INFO[Path.PROFILE].useRegex,
  },
  {
    component: (
      <Route
        exact
        path={`${Path.FORGOT_PASSWORD}`}
        component={ForgotPasswordPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    component: (
      <Route
        exact
        path={`${Path.RESET_PASSWORD}/:uidb64/:token`}
        component={ForgotPasswordPage}
      />
    ),
    role: '',
    useRegex: true,
  },
  {
    /* Common Route */
    component: <Route exact path={Path.LOGIN} component={LoginPage} />,
    role: '',
    useRegex: true,
  },
  {
    /* Put at end of Router list */
    component: <Route component={NotFoundPage} />,
    role: '',
    useRegex: true,
  },
];

const ROUTE_LIST_MULTIPLE_ROLES = [];

export function App() {
  const { i18n } = useTranslation();
  const { hasRole, hasOneRole } = useRole();

  const { loginStatus } = useSelector(selectLogin);

  const renderRouter = () => {
    const normal_routes = ROUTE_LIST.map(it => {
      if (it.role && isHavingToken()) {
        // check user has one role
        if (hasRole(it.role, it.useRegex)) {
          return it.component;
        }
        return null;
      }
      return it.component;
    });
    // const routes_multiple_roles = ROUTE_LIST_MULTIPLE_ROLES.map(it => {
    //   // check user has one of roles
    //   if (it.useHasOneRole && it.roles && isHavingToken()) {
    //     if (hasOneRole(it.roles, it.useRegex)) {
    //       return it.component;
    //     }
    //     return null;
    //   }
    //   return it.component;
    // });
    const all_routes = [...normal_routes];
    // const all_routes = [...routes_multiple_roles, ...normal_routes];
    return all_routes;
  };

  React.useEffect(() => {
    if (loginStatus === RequestStatus.SUCCESS) renderRouter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Palette"
        defaultTitle="Palette"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Palette" />
      </Helmet>
      <GlobalContainer>
        <Switch>{renderRouter()}</Switch>
      </GlobalContainer>
      <GlobalStyle />
    </BrowserRouter>
  );
}
