import { useDispatch, useSelector } from 'react-redux';
import { useMemberSlice } from './slice';
import { selectMember } from './slice/selectors';
import { useForgotPasswordSlice } from '../ForgotPasswordPage/slice';
import { selectForgotPassword } from '../ForgotPasswordPage/slice/selectors';

const useMember = () => {
  const dispatch = useDispatch();
  const { forgotPasswordRequest, resetForgotPasswordStatus } =
    useForgotPasswordSlice().actions;
  const { forgotPasswordStatus } = useSelector(selectForgotPassword);
  const {
    actions: { updateMemberInfoRequest },
  } = useMemberSlice();

  const { updateMemberInfoStatus, memberInfo } = useSelector(selectMember);

  const updateMember = ({ id, formData }) => {
    dispatch(updateMemberInfoRequest({ objectId: id, formData: formData }));
  };
  const forgotPass = payload => {
    dispatch(forgotPasswordRequest({ email: payload }));
  };
  const resetForgotPass = () => {
    dispatch(resetForgotPasswordStatus());
  };

  return {
    updateMember,
    updateMemberInfoStatus,
    memberInfo,
    forgotPass,
    forgotPasswordStatus,
    resetForgotPass,
  };
};

export default useMember;
