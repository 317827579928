import { useEffect, useState } from 'react';
// UI
import { Helmet } from 'react-helmet-async';
import { Container } from 'app/components/Container';
import { Wrapper, Content } from 'app/components/Wrapper';
import BasicInfo from './components/BasicInfo';
import { Paper } from '@mui/material';
import { PageTitle } from 'app/components/PageTitle';
// Library
import _ from 'lodash';
import { useForgotPasswordSlice } from 'app/pages/ForgotPasswordPage/slice/';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from './slice/selectors';
import { useProfileSlice } from './slice';
import { convertDate } from 'utils/moment';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { selectForgotPassword } from 'app/pages/ForgotPasswordPage/slice/selectors';
import { useMemberSlice } from 'app/pages/MemberPage/slice';
import { RequestStatus } from 'constants/API';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';

/**
 *
 * ProfilePage
 *
 */
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { checkIsRequesting } from 'utils/helper';
import { Loader } from 'app/components/Loader';
import { useTranslation } from 'react-i18next';
import useMember from '../MemberPage/useMember';

export const ProfilePage = () => {
  const { userSessionData } = useSelector(selectGlobal);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actions } = useProfileSlice();
  const profileForm = useForm();
  const changePasswordForm = useForm();
  const { info, getProfileStatus } = useSelector(selectProfile);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword(prevShowPassword => !prevShowPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(prevShowPassword => !prevShowPassword);
  };
  const { setSuccessMessages, setErrorMessages, setBreadcumbs } =
    useGlobalSlice().actions;
  const { changePasswordRequest, resetChangePasswordRequestStatus } =
    useForgotPasswordSlice().actions;

  const {
    actions: {
      resetMemberInfoRequestStatus,
      updateMemberInfoRequest,
      uploadMemberAvatarRequest,
    },
  } = useMemberSlice();
  const { updateMember } = useMember();

  useEffect(
    () => () => {
      dispatch(resetMemberInfoRequestStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  const { updateMemberInfoStatus, uploadMemberAvatarStatus, memberInfo } =
    useSelector(selectMember);
  const { changePasswordStatus } = useSelector(selectForgotPassword);

  const isLoading = checkIsRequesting([getProfileStatus]);

  useEffect(() => {
    if (updateMemberInfoStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Member update successful!']));
      dispatch(resetMemberInfoRequestStatus());
    }
    if (updateMemberInfoStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Member update fail!']));
      dispatch(resetMemberInfoRequestStatus());
    }
    if (uploadMemberAvatarStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Member avatar update successful!']));
      dispatch(resetMemberInfoRequestStatus());
    }
    if (uploadMemberAvatarStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['This avatar is too big!']));
      dispatch(resetMemberInfoRequestStatus());
    }
    if (changePasswordStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Change password successful!']));
      changePasswordForm.setValue('confirm_password', '');
      changePasswordForm.setValue('new_password', '');
      changePasswordForm.setValue('old_password', '');
      dispatch(resetChangePasswordRequestStatus());
    }
    if (changePasswordStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Wrong password!']));
      changePasswordForm.setValue('confirm_password', '');
      changePasswordForm.setValue('new_password', '');
      changePasswordForm.setValue('old_password', '');
      dispatch(resetChangePasswordRequestStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMemberInfoStatus, changePasswordStatus, uploadMemberAvatarStatus]);

  useEffect(() => {
    dispatch(actions.getProfileRequest(userSessionData.profile.id));
    dispatch(setBreadcumbs([{ title: 'User Profile' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (info) {
      const data = _.cloneDeep(info);
      profileForm.reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProfileStatus]);

  const handleSaveData = formData => {
    let _formData = new FormData();
    _formData.append('phone', formData.phone || memberInfo?.phone);
    _formData.append('code', formData.code || memberInfo?.code);
    _formData.append(
      'department',
      formData.department || memberInfo?.department,
    );
    _formData.append('email', formData.email || memberInfo?.email);
    _formData.append('full_name', formData.full_name || memberInfo?.full_name);
    _formData.append('address', formData.address || memberInfo?.address);
    _formData.append(
      'sys_language',
      formData.sys_language || memberInfo?.sys_language,
    );

    updateMember({ id: info?.id, formData: _formData });
    changePasswordForm.reset();
  };
  const handleChangePassword = formData => {
    setShowPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    dispatch(changePasswordRequest(formData));
  };
  const callAPIUploadImage = () => {
    let avatar = profileForm.getValues('avatar');
    if (avatar) {
      dispatch(uploadMemberAvatarRequest({ id: info?.id, avatar }));
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('Profile Info')}</title>
        <meta name="description" content="Profile Info" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <PageTitle>{t('User Profile')}</PageTitle>
            <Paper
              id="profile-form"
              component="form"
              style={{ padding: '24px 100px', borderRadius: '12px' }}
            >
              <BasicInfo
                memberForm={profileForm}
                handleSaveData={handleSaveData}
                handleChangePassword={handleChangePassword}
                changePasswordForm={changePasswordForm}
                callAPIUploadImage={callAPIUploadImage}
                showPassword={showPassword}
                showNewPassword={showNewPassword}
                showConfirmPassword={showConfirmPassword}
                handleClickShowPassword={handleClickShowPassword}
                handleClickShowNewPassword={handleClickShowNewPassword}
                handleClickShowConfirmPassword={handleClickShowConfirmPassword}
                handleMouseDownPassword={handleMouseDownPassword}
              />
            </Paper>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
};
